<template>
  <div>
    <mly-header></mly-header>

    <!--<section id="slider">
            <slider animation="normal" :interval="3000" :control-btn="false" :speed="400" :height="'3.76rem'">
                <slider-item v-for="(item, index) in images" :key="index">
                    <img :src="'images/'+item" alt="" class="img-responsive">
                </slider-item>
            </slider>
        </section>
        <section id="game-list">
            <div class="list-item" v-for="item in game_list">
                <img src="images/game_icon.png" class="img-responsive" alt="">
                <div class="description">
                    <p>天使纪元</p>
                    <p>游戏类型|375.98M</p>
                    <p>天使纪元》史诗魔幻体验！是一款</p>
                </div>
                <button type="button">开始</button>
            </div>
            <div class="foot">
                <a href="javascript:void(0)" @click="more_game">更多游戏&gt;&gt;</a>
            </div>
    </section>-->
    <img
      src="https://tjy-1253841540.cosgz.myqcloud.com/pic/official_website_wap/project_files/images/img_please_wait.jpg"
      alt
      class="please_wait"
    >

    <mly-toolbar></mly-toolbar>
  </div>
</template>
<script>
export default {};
</script>
<style>
.please_wait {
  width: 4.36rem;
  height: 3.52rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.76rem;
  margin-left: -2.18rem;
}
</style>

